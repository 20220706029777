import React from "react"

import Heading from "components/elements/heading"
import ContactButton from "components/form/contact-button"
import Svg from "components/svgs/svg"
import * as s from "./contact.module.sass"

const Contact = () => {
  return (
    <div id="contact" className={s.component}>
      <div className={s.container}>
        <Svg parentClass={s.icon} id="nsIcon" />

        <Heading
          parentClass={s.heading}
          surtitle="Need something?"
          title="We're here for you"
        />

        <ContactButton />
      </div>
    </div>
  )
}

export default Contact
