import React from "react"

import { smoothScrollTo } from "components/utils"
import Buttons from "components/form/buttons"
import Button from "components/form/button"
import cx from "classnames"
import * as s from "./hello.module.sass"

const Hello = () => {
  const everyone = EVERYONE.map((glyph) => (
    <span key={glyph.color} style={{ color: glyph.color }}>
      {glyph.letter}
    </span>
  ))
  const backgroundImage = `url("${process.env.SITE_URL}/games/ArtGalleryInside.jpg")`
  const style = {
    backgroundImage,
  }

  return (
    <div id="hello" className={s.component}>
      <div className={s.background} style={style} />

      <div className={cx("container", s.container)}>
        <div className={s.tagline}>
          We make online, multiplayer games for <strong>{everyone}</strong>
        </div>

        <Buttons parentClass={s.buttons}>
          <Button
            parentClass={s.button}
            color="pink"
            onClick={() => smoothScrollTo("games")}
          >
            Our first game
          </Button>
          <Button
            parentClass={s.button}
            outlined
            onClick={() => smoothScrollTo("careers")}
          >
            Join us
          </Button>
        </Buttons>
      </div>
    </div>
  )
}

const EVERYONE = [
  { letter: "e", color: "#EB2869" },
  { letter: "v", color: "#EB28CA" },
  { letter: "e", color: "#AA28EB" },
  { letter: "r", color: "#6928EB" },
  { letter: "y", color: "#288AEB" },
  { letter: "o", color: "#26DEDE" },
  { letter: "n", color: "#28EB8A" },
  { letter: "e", color: "#99E600" },
]

export default Hello
