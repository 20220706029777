import React from "react"

import Heading from "components/elements/heading"
import Jobs from "components/components/jobs"
import JobsWidget from "components/components/jobs-widget"
import * as s from "./careers.module.sass"

const jobsData = require("data/jobs.json")

const Careers = () => {
  const backgroundImage = `url("${process.env.SITE_URL}/images/desks.jpg")`
  const style = {
    backgroundImage,
  }

  return (
    <div id="careers" className={s.component} style={style}>
      <Heading
        parentClass={s.heading}
        title="Careers"
        version="half"
      />
      <div className="container">
        <p className={s.contact}>
		 <a href="https://netspeakgames.teamtailor.com">Be part of our journey</a>
          
        </p>
        <JobsWidget />
      </div>
    </div>
  )
}

export default Careers
