import React from "react"

import Heading from "components/elements/heading"
import Content from "components/elements/content"
import Svg from "components/svgs/svg"
import * as s from "./story.module.sass"

const storyMarkdown = require("data/story.md")

const Story = () => {
  return (
    <div id="story" className={s.component}>
      <Svg parentClass={s.icon} id="nsIcon" />

      <Heading
        parentClass={s.heading}
        surtitle="All work and all play"
        title="Our story"
      />

      <Content parentClass={s.content} content={storyMarkdown.default} />
    </div>
  )
}

export default Story
