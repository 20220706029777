import React from "react"
import { Helmet } from "react-helmet"

import Layout from "layouts/default"
import Header from "components/components/header"
import Hello from "components/index/hello"
import Story from "components/index/story"
import Game from "components/index/game"
import Careers from "components/index/careers"
import Contact from "components/index/contact"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Netspeak Games</title>
    </Helmet>
    <Header version="index" />
    <Hello />
    <Story />
    <Game />
    <Careers />
    <Contact />
  </Layout>
)

export default IndexPage
