import React from 'react';
import { Helmet } from "react-helmet"

import * as s from './jobs-widget.module.sass';

const JobsWidget = props => {
  const {
    id,
  } = props;

  return (
    <div className={s.component}>
      <Helmet>
        <script
          src="https://scripts.teamtailor-cdn.com/widgets/production/jobs.js"
          async
          charset="utf-8"
        ></script>
      </Helmet>
        <div
          className="teamtailor-jobs-widget"
          data-teamtailor-limit="20"
          data-teamtailor-api-key="UaXIjs7cnma9sk4NknL_rykyLxzOa4UbPSFfkOhi"
        ></div>
    </div>
  )
}

export default JobsWidget;
