import React from "react"

import Button from "components/form/button"
import cx from "classnames"
import * as s from "./static-image.module.sass"

const StaticImage = props => {
  const {
    src,
    alt = "Static image",
    width,
    height,
    to,
    href,
    parentClass,
    unstyled = false,
  } = props;

  const componentClass = cx({
    [s.component]: !unstyled,
    [parentClass]: parentClass,
  });

  const content = (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height} />
    );

  if (to) {
    return (
      <Button unstyled to={to} parentClass={componentClass}>
        {content}
      </Button>
    )
  }

  if (href) {
    return (
      <Button unstyled href={href} parentClass={componentClass}>
        {content}
      </Button>
    )
  }

  return (
    <figure className={componentClass}>
      {content}
    </figure>
)}

export default StaticImage
