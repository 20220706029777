import React from "react"

import Heading from "components/elements/heading"
import Content from "components/elements/content"
import Svg from "components/svgs/svg"
import StaticImage from "components/media/static-image"
import cx from "classnames"
import * as s from "./game.module.sass"

const gameMarkdown = require('data/game.md');
const gameJSON = require('data/game.json');

const Game = () => {
  const gameImage = `${process.env.SITE_URL}/games/sunshine_days.png`;

  return (
    <div id="games" className={s.component}>
      <Heading
        parentClass={s.heading}
        surtitle="Inclusive, diverse but above all fun"
        title="Our first game"
        version="light"
        />

      <div className={cx("container", s.container)}>
        <div className={cx("columns", s.columns)}>
          <div className={cx("column", s.text)}>
            <Content parentClass={s.content} light content={gameMarkdown.default} />
          </div>

          <div className={cx("column is-7", s.media)}>
            <StaticImage
              parentClass={s.gameplay}
              src={gameImage}
              />

            <div className={s.platforms}>
              {gameJSON.platforms.map(platform => {
                if (!platform.show) {
                  return null;
                }

                return (
                  <Svg
                    key={platform.id}
                    id={platform.id}
                    parentClass={s.platform}
                    light
                    href={platform.href}
                />
                )
              })}
            </div>

            <div className={s.downloads}>
              {gameJSON.stores.ios.show && (
                <Svg
                id="appStore"
                parentClass={s.download}
                href={gameJSON.stores.ios.href}
                />
              )}

              {gameJSON.stores.googlePlay.show && (
                <StaticImage
                  parentClass={s.platform}
                  src={`${process.env.SITE_URL}/images/google-play-badge.png`}
                  width={212}
                  height={63}
                  href={gameJSON.stores.googlePlay.href}
                  />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Game
